import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";

export default ({ data }) => {
  const post = data.markdownRemark;
  const featureImgFuild = post.frontmatter.image;
  return (
    <Layout>
      <SEO
        title={`${post.frontmatter.title} |`}
        socialImage={featureImgFuild}
        description={post.frontmatter.abstract}
      />
      <main className="project-template">
        <h1>{post.frontmatter.title}</h1>
        <span className="date">{post.frontmatter.date}</span>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        {/* <img src={featureImgFuild} /> */}
      </main>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image
        date
        abstract
      }
    }
  }
`;
